@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: black;
    line-height: 1.6;
    letter-spacing: 0.1px;
}

* {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

p {
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h4,
h5,
h6 {
    line-height: 1.2;
}

h1 {
    font-size: 36px;
    font-weight: 700;
}

h2 {
    font-size: 32px;
    font-weight: 700;
}

h3 {
    font-size: 28px;
    font-weight: 600;
}

h4 {
    font-size: 24px;
    font-weight: 600;
}

h5 {
    font-size: 20px;
    font-weight: 500;
}

h6 {
    font-size: 16px;
    font-weight: 500;
}

.btn {
    background: #007bff;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 5px;
    transition: all 0.3s ease;
}


.btn:hover {
    text-decoration: none;
    opacity: 0.8;
}

/* Focus state */
.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    opacity: 0.8;
}

.btn-satisfy {
    background: #55c1ac;
    color: #1c1f1f;
}

.btn-unsatisfy {
    background: #fab5a8;
    color: #702c1f;
}

.btn-satisfied {
    background: #ceede7;
    color: #1a1a1a;
}

.btn-improve {
    background: #bfe1fa;
    color: #1f415a;
}

.btn-outline {
    border: solid 2px #1c1f1f;
    background: none;
    color: #1a1a1a;
}

.btn-one {
    /* Warm Orange  */
    /* background: #ff9966; */
    background: #FF0000;
    color: #fff;
}

.btn-two {
    /* Soft Blue */
    /* background: #66ccff; */
    background: #FF4500;
    color: #fff;
}

.btn-three {
    /* Soft Gold */
    /* background: #ffcc66; */
    background: #FF8C00;
    color: #fff;
}

.btn-four {
    /* Light Green */
    /* background: #66ff99; */
    background: #FFD700;
    color: #fff;
}

.btn-five {
    /* Red */
    /* background: #ff6666; */
    background: #FAFAD2;
    color: #fff;
}

.btn-six {
    /* Green */
    /* background: #42bb42; */
    background: #FFFF00;
    color: #fff;
}

.btn-seven {
    /* Cool Blue */
    /* background: #6699ff; */
    background: #90EE90;
    color: #fff;
}

.btn-eight {
    /* Purple */
    /* background: #b366ff; */
    background: #00FF7F;
    color: #fff;
}

.btn-nine {
    /* Gray */
    /* background: #999999; */
    background: #3CB371;
    color: #fff;
}

.btn-ten {
    /* Teal */
    /* background: #66ffcc; */
    background: #008000;
    color: #fff;
}

.btn-default {
    /* Gray */
    background: #999999;
    color: #fff;
}

header {
    height: 10px;
    padding: 20px 50px;
}

header .logo {}

header .logo img {
    height: 60px;
}

.wrapper {
    max-width: 100%;
    margin: 10px 50px;
}

.survey {
    text-align: center;
    /* Center child elements */
}

.survey .infoText {
    margin: auto;
    max-width: 700px;
    margin-bottom: 50px;
}

.survey .infoText h3 {
    text-align: center;
    margin-bottom: 30px;
}

.survey .infoText p {
    color: #7e7e7e;
}

.survey .hightlightText {
    background: #e6e6e6;
    font-size: 22px;
    font-weight: 600;
    padding: 30px;
    margin: auto;
    max-width: 880px;
    border-radius: 15px;
    margin-bottom: 25px;
    text-align: center;
    display: inline-block;
    /* Shrink to fit content width */
}

.satisfiedBtns {
    text-align: center;
}

.satisfiedBtns .inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
}

.satisfiedBtns .btn {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 14px 30px;
    border-radius: 10px;
    margin: 0 15px;
    margin-bottom: 15px;
}

.satisfiedBtns .btn i {
    font-size: 34px;
    margin-right: 20px;
    color: #1a1a1a;
}

.thanks {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #8fd14f;
    margin-top: 30px;
}

.not-available {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    background-color: #e6e6e6; /* Light red for a warning feel */
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 15px;
    margin: 30px auto;
    width: 80%; /* Adjust width for responsiveness */
    max-width: 600px; /* Limit max width for readability */
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

.thanks i {
    color: #8fd14f;
    font-size: 24px;
    margin-right: 12px;
}

.not-available i {
    color: #eb2a2a;
    font-size: 24px;
    margin-right: 12px;
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.field {
    margin: auto;
    max-width: 880px;
}

.field .form-control {
    width: calc(100% - 25px)
}

.field .form-control:focus-visible {
    outline: #1a1a1a 1px;
}

.submitBtn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.submitBtn .btn {
    min-width: 280px;
}


@media only screen and (max-width: 992px) {
    .survey .infoText h3 {
        font-size: 22px;
    }

    .survey .hightlightText {
        font-size: 18px;
        padding: 24px;
    }
}

@media only screen and (max-width: 768px) {
    .wrapper {
        margin: 10px 20px;
    }

    header {
        height: 50px;
    }

    header .logo {
        text-align: center;
    }

    header .logo img {
        height: 50px;
    }

    .survey .infoText {
        margin-bottom: 30px;
    }

    .survey .infoText h3 {
        font-size: 15px;
    }

    .survey .hightlightText {
        font-size: 14px;
        padding: 16px;
    }
}

@media only screen and (max-width: 500px) {

  
    .satisfiedBtns .btn {
        width: 80%;
        margin: 0;
        margin-bottom: 15px;
        font-size: 15px;
        justify-content: center;
    }

    .satisfiedBtns .inner {
        display: block;
        margin-top: 25px;
    }

    .satisfiedBtns .inlineBtns .btn {
        width: 45px;
        padding: 10px 10px;
        margin: 2px;
        display: flex;
        justify-content: center;
    }

    .submitBtn .btn {
        min-width: 280px;
        width: 100%;
        font-size: 15px;
    }

    .thanks {
        font-size: 14px;
    }

    .not-available {
        font-size: 14px;
    }

    .custom-btn-response {
        margin: 0 3px;
        display: flex;
        justify-content: center;
    }
}

/*
 @media only screen and (max-width: 500px) {
    .satisfiedBtns .btn {
        width: 95%;
        margin: 0;
        margin-bottom: 15px;
        font-size: 15px;
        justify-content: center;
    }

    .satisfiedBtns .inlineBtns .btn {
        width: 45px;
        padding: 10px 10px;
        margin: 2px;
        display: flex;
        justify-content: center;
    }
    .submitBtn .btn {
        min-width: 280px;
        width: 100%;
        font-size: 15px;
    }
    .thanks {
        font-size: 14px;
    }


    .not-available {
        font-size: 14px;
    }

    .custom-btn-response {
        margin: 0 3px;
    }

}
*/

.textarea-thanks {
    /* position: absolute;
    right: 140px; */
    color: #8fd14f;
    font-size: 14px;
    font-weight: 700;
    margin-top: 12px;
    display: flex;
    justify-content: center;


    /*
    position: absolute;
    right: 265px;  
    color: #8fd14f;
    font-size: 14px;
    font-weight: 700;
    margin-top: 5px;
    white-space: nowrap;
    */

}

.textarea-error {
    /* position: absolute;
    right: 265px; */
    color: red;
    font-size: 14px;
    margin-top: 12px;
    /* white-space: nowrap; */
}

i.fa-solid {
    font-size: 28px;
}


.participant-list {
    background-color: #e6f2ff;
    /* Light blue background */
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    /* margin-bottom: 10px; */
    text-align: center;
    display: inline-block;
}

.participant-list h5 {
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    font-size: 16px;
}

.participant-item {
    color: #333;
    font-size: 16px;
    margin: 5px 0;
}

.participant-item p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
}

.scale-status {
    /* background-color: #66FF99; */
    padding: 5px;
    border-radius: 5px;
    max-width: 120px;
    margin: 10px auto;
    text-align: center;
    /* display: inline-block; */
}

.scale-status h5 {
    font-weight: bold;
    color: #333;
    font-size: 12px;
    position: relative;
    display: inline-flex;
    align-items: center;
}

.scale-status h5::before {
    content: "✔";
    /* Unicode for checkmark */
    color: #28a745;
    /* Green color for the checkmark */
    font-size: 13px;
    margin-left: 10px;
    /* Space between text and checkmark */
    margin-right: 5px;
}

.see-more-link {
    color: #007bff;
    cursor: pointer;
    font-size: 0.9em;
    text-decoration: none;
    margin-top: 10px;
    display: inline-block;
  }
  
  .see-more-link:hover {
    text-decoration: underline;
  }
  
  
  /* Survey Response CSS */
  
  .thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .thank-you-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    width: 70%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .icon-container {
    font-size: 50px;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  .message-text {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  .response-container {
    margin-top: 30px;
    width: 70%;
  }
  
  .response-heading {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .feedback-card {
    background: #ffffff;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .question {
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .answer-box {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    border-radius: 5px;
    display: inline-block;
  }

  .reason {
    font-size: 14px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  